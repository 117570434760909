export const initialValues = {
  number: '',
};

export const mockExisting = [
  {
    id: 1,
    placeholder: 'Mandate number',
    fieldType: 'text',
    name: 'number',
    classNames: 'w-full',
  },
];
