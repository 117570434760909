import { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { selectUserToken } from '../../../redux/features/user/userSelectors';
import { useGetRealtiesQuery } from '../../../redux/services/realties/realtiesApi';
import { setTotalSearchResults } from '../../../redux/features/dashboard/dashboardSlice';

import { ErrorMsg } from '../ErrorMsg';
import { CardRealty } from '../realtyCard/CardRealty';

import { errorMessages } from '../../../data/constants';
import { LoaderProgress } from '../LoaderProgress';
import { PaginationButtons } from '../pagination/PaginationButtons';
import { handlePageClick } from '../../../utils/utils';

export const RealtyList = ({ setIsLoading = () => {}, isSearch = false }) => {
  const [isLoadingRealties, setIsRealtiesLoading] = useState(false);
  const [error, setError] = useState(null);

  const [page, setPage] = useState(null);

  const dispatch = useDispatch();
  const userToken = useSelector(selectUserToken);
  const {
    data: realties = {},
    isLoading: isRealtiesLoading,
    isFetching: isRealtiesFetching,
    error: realtiesError,
  } = useGetRealtiesQuery(
    {
      token: userToken,
      page,
    },
    { refetchOnMountOrArgChange: true, skip: isSearch }
  );

  const loadRealties = () => {
    setIsRealtiesLoading(isRealtiesLoading);

    if (!isRealtiesLoading && realties && !realtiesError) {
      setError(null);
      dispatch(setTotalSearchResults(realties?.total ? realties.total : 0));
    }

    if (realtiesError) {
      setError(errorMessages.data);
    }
  };

  const handlePrevClick = () => {
    handlePageClick(realties?.links?.prev, setPage);
  };

  const handleNextClick = () => {
    handlePageClick(realties?.links?.next, setPage);
  };

  useEffect(() => {
    setIsLoading(isRealtiesLoading);
  }, [isRealtiesLoading]);

  useEffect(() => {
    setIsRealtiesLoading(isRealtiesFetching);
  }, [isRealtiesFetching]);

  useEffect(() => {
    loadRealties();
  }, [realties?.data, isRealtiesLoading]);

  return (
    <>
      {!isSearch && (
        <>
          <div className="flex justify-end mb-3">
            <PaginationButtons
              links={realties?.links}
              meta={realties?.meta}
              handleNextClick={handleNextClick}
              handlePrevClick={handlePrevClick}
            />
          </div>

          <div className="flex gap-3 overflow-x-auto hide-scroll">
            {realties?.data?.map(realty => {
              return (
                <CardRealty
                  key={realty?.id}
                  realty={realty}
                />
              );
            })}
          </div>
        </>
      )}

      {isLoadingRealties && <LoaderProgress />}

      {error && (
        <div className="flex justify-center w-full h-20">
          <ErrorMsg message={errorMessages.data} />
        </div>
      )}
    </>
  );
};
