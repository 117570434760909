import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from '../shadcn/table';

const invoices = [
  {
    number: 'N43',
    agency: 'SAS VESSNART CANNES',
    type: 'Simple',
    startDay: '29/04/2024',
    endDay: '29/07/2025',
    relatedListings: '8793066 Villa Living size 200m² Bedrooms number: 6',
  },
  {
    number: 'N43',
    agency: 'SAS VESSNART CANNES',
    type: 'Simple',
    startDay: '29/04/2024',
    endDay: '29/07/2025',
    relatedListings: '8793066 Villa Living size 200m² Bedrooms number: 6',
  },
  {
    number: 'N43',
    agency: 'SAS VESSNART CANNES',
    type: 'Simple',
    startDay: '29/04/2024',
    endDay: '29/07/2025',
    relatedListings: '8793066 Villa Living size 200m² Bedrooms number: 6',
  },
  {
    number: 'N43',
    agency: 'SAS VESSNART CANNES',
    type: 'Simple',
    startDay: '29/04/2024',
    endDay: '29/07/2025',
    relatedListings: '8793066 Villa Living size 200m² Bedrooms number: 6',
  },
  {
    number: 'N43',
    agency: 'SAS VESSNART CANNES',
    type: 'Simple',
    startDay: '29/04/2024',
    endDay: '29/07/2025',
    relatedListings: '8793066 Villa Living size 200m² Bedrooms number: 6',
  },
  {
    number: 'N43',
    agency: 'SAS VESSNART CANNES',
    type: 'Simple',
    startDay: '29/04/2024',
    endDay: '29/07/2025',
    relatedListings: '8793066 Villa Living size 200m² Bedrooms number: 6',
  },
];

export const MandateTable = () => {
  return (
    <Table className="mt-4">
      <TableHeader>
        <TableRow>
          <TableHead className="w-[100px]">Number</TableHead>
          <TableHead>Agency</TableHead>
          <TableHead>Type</TableHead>
          <TableHead>Start date</TableHead>
          <TableHead>End date</TableHead>
          <TableHead>Related listings</TableHead>
        </TableRow>
      </TableHeader>

      <TableBody>
        {invoices.map((invoice, index) => (
          <TableRow key={index}>
            <TableCell>{invoice.number}</TableCell>
            <TableCell>{invoice.agency}</TableCell>
            <TableCell>{invoice.type}</TableCell>
            <TableCell>{invoice.startDay}</TableCell>
            <TableCell>{invoice.endDay}</TableCell>
            <TableCell>{invoice.relatedListings}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
