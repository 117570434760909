import { useState } from 'react';

import cn from 'classnames';

import { ResultsTableItem } from '../ResultsTableItem';
import { DateRangePicker } from '../../form/inputs/DateRangePicker';
import { ButtonTemplate } from '../buttons/ButtonTemplate';

export const ResultsTable = ({ title, list }) => {
  const [isTableOpen, setIsTableOpen] = useState(false);

  const handleClick = () => {
    setIsTableOpen(!isTableOpen);
  };

  return (
    <div
      className={cn({
        'flex flex-col justify-between gap-4 py-3 px-2 w-[430px] min-w-[430px] bg-lightestGrayColor border-[1px] border-gray-200 lg:w-[50%]':
          isTableOpen,
      })}
    >
      <div className="flex justify-between items-center gap-1">
        <ButtonTemplate
          text={title}
          classes={
            isTableOpen
              ? 'bg-lightestGrayColor border-lightestGrayColor font-bold italic'
              : 'bg-white border-gray-200 '
          }
          handleClick={handleClick}
        />

        {isTableOpen && <DateRangePicker />}
      </div>

      {isTableOpen && (
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <tbody>
            {list.map(({ id, title, value1, value2 }) => {
              return (
                <ResultsTableItem
                  key={id}
                  title={title}
                  value1={value1}
                  value2={value2}
                />
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};
