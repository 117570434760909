import { Home, AlarmClock, SquareUser, Mail, Bell, User } from 'lucide-react';
import { selectNotificationsDataUnread } from '../redux/features/dashboard/dashboardSelectors';

import { NotificationBar } from '../components/ui/NotificationBar';

export const sideLinksAuthorised = [
  {
    id: 1,
    to: '/',
    title: 'My page',
    icon: Home,
  },
  {
    id: 2,
    to: '/reminders',
    title: 'Reminders',
    icon: AlarmClock,
  },
  {
    id: 3,
    to: '/clients',
    title: 'Clients',
    icon: SquareUser,
  },
  {
    id: 4,
    to: '/emails',
    title: 'Emails',
    icon: Mail,
  },
  {
    id: 5,
    title: 'Notifications',
    icon: Bell,
    classes: 'mt-4',
    component: NotificationBar,
    counterTipRedux: selectNotificationsDataUnread,
  },
  {
    id: 6,
    to: '/settings',
    title: 'Settings',
    icon: User,
    subtitle: 'username',
  },
];

export const sideLinksNonAuthorised = [
  {
    id: 1,
    to: '/login',
    title: 'Login',
    icon: User,
  },
  {
    id: 2,
    to: '/registration',
    title: 'Registration',
    icon: SquareUser,
  },
];

export const navLinks = [
  { id: 1, title: 'Requests', redirectLink: '/' },
  { id: 2, title: 'Deals', redirectLink: '/' },
];
