import { Menu } from 'lucide-react';

import { ButtonTemplate } from '../../../../ui/buttons/ButtonTemplate';
import { Dialog } from '../../../../ui/Dialog';
import { useState } from 'react';
import { MandateTable } from '../../../../ui/MandateTable';
import { AddMandateForm } from '../AddMandateForm';
import { InputsTemplate } from '../../../inputs/InputsTemplate';

export const MandateBlockForm = ({ formProps = {}, inputsList = [] }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [classes, setClasses] = useState(null);

  const handleClick = ({ className }) => {
    if (className) {
      setClasses(className);
    } else {
      setClasses('');
    }
    setIsOpen(true);
  };

  const handleDeleteClick = () => {
    inputsList.forEach(input => {
      if (formProps?.setFieldValue) {
        formProps.setFieldValue(input.name, '');
      }
    });
  };

  return (
    <>
      <div className="flex items-center gap-3">
        <ButtonTemplate
          text={'Mandate List'}
          handleClick={() =>
            handleClick({
              dialogComponent: MandateTable,
              className: 'max-w-[90%] sm:max-w-[810px]',
            })
          }
          icon={Menu}
          isIconText={true}
        />

        <ButtonTemplate
          text={'Add existing'}
          handleClick={() =>
            handleClick({ dialogComponent: AddMandateForm, isExisting: true })
          }
        />
      </div>

      <InputsTemplate
        formProps={formProps}
        inputsList={inputsList}
      />

      <ButtonTemplate
        text={'Delete this mandate'}
        handleClick={handleDeleteClick}
      />

      {isOpen && (
        <Dialog
          content={<AddMandateForm />}
          classes={classes ? classes : 'max-w-[290px] sm:max-w-[420px]'}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        />
      )}
    </>
  );
};
