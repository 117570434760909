import { Heart } from 'lucide-react';

import { ButtonTemplate } from '../buttons/ButtonTemplate';
import { IconButtonTemplate } from '../buttons/IconButtonTemplate';
import { CheckboxTemplate } from '../../form/inputs/CheckboxTemplate';
import { useSelector } from 'react-redux';
import { selectTotalCount } from '../../../redux/features/dashboard/dashboardSelectors';

export const SortBlock = () => {
  const totalCount = useSelector(selectTotalCount);

  const handleFavClick = () => {};

  const handleDownloadPDF = () => {};

  return (
    <div className="flex items-center gap-4 mb-4">
      <div>
        <span className="text-xs">{`${totalCount} of results`}</span>
      </div>

      <CheckboxTemplate checkboxText={'Map'} />

      <IconButtonTemplate
        handleClick={handleFavClick}
        icon={Heart}
        tooltipText="Add all to favorites"
      />

      <ButtonTemplate
        text={'Download PDFs'}
        handleClick={handleDownloadPDF}
      />
    </div>
  );
};
