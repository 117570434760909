import { useState } from 'react';
import { useNavigate } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';

import { FormTemplate } from '../components/form/forms/FormTemplate';
import { ButtonTemplate } from '../components/ui/buttons/ButtonTemplate';
import { InputsTemplate } from '../components/form/inputs/InputsTemplate';
import { ErrorMsg } from '../components/ui/ErrorMsg';

import { initialValues, mock } from '../lib/mocks/settings-mock';
import { Container } from '../layouts/Container';

export const Emails = () => {
  const [error, setError] = useState(null);

  const handleSubmit = selectedItem => {
    console.log(selectedItem);
  };

  return (
    <Container>
      <div className="flex flex-col gap-4 w-full">
        <span className="text-lg font-bold color-blackColor">Recepients</span>

        {/* <FormTemplate
          initialValues={initialValues}
          isWithoutButton={true}
          handleSubmitForm={handleSubmit}
          // isLoadingData={isLoadingData}
        >
          {formProps => (
            <InputsTemplate
              formProps={formProps}
              inputsList={mock}
              isWithoutButton={true}
            />
          )}
        </FormTemplate> */}

        {error && error.length > 0 && <ErrorMsg message={error} />}
      </div>
    </Container>
  );
};
