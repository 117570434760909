import { api } from '../api';
import { links } from '../../../data/links';

export const searchApi = api.injectEndpoints({
  endpoints: build => ({
    getSearchRealties: build.query({
      query: ({ token, search_query, search_filters, sort_by, page }) => {
        let newUrl = `${links.search}${search_query ? `?search_query=${search_query}&search_type=1` : '?search_type=1'}`;

        if (search_filters && search_filters.length > 0) {
          newUrl = `${links.search}${search_filters}`;
        };

        if (sort_by) {
          newUrl += `&sort_by=${sort_by}`;
        }

        if (page) {
          newUrl += `&page=${page}`;
        }

        const params = {
          url: newUrl,
          headers: { Authorization: `Bearer ${token}` }
        };

        return params;
      },
      transformResponse: responseData => {
        const searchData = responseData?.data;

        if (searchData) {
          return searchData;
        } else {
          return {};
        }
      },
      providesTags: result =>
        result?.realties
          ? [
            ...result.realties.map(({ id }) => ({ type: 'SearchRealties', id })),
            { type: 'SearchRealties', id: 'LIST' },
          ]
          : [{ type: 'SearchRealties', id: 'LIST' }],
    }),
    getSearchInputRealties: build.query({
      query: ({ token, search_query }) => {
        let newUrl = `${links.search}${search_query ? `?search_query=${search_query}&search_type=1` : '?search_type=1'}`;

        const params = {
          url: newUrl,
          headers: { Authorization: `Bearer ${token}` }
        };

        return params;
      },
      transformResponse: responseData => {
        const searchData = responseData?.data;

        if (searchData) {
          return searchData;
        } else {
          return {};
        }
      },
      providesTags: result =>
        result?.realties
          ? [
            ...result.realties.map(({ id }) => ({ type: 'SearchRealtiesInput', id })),
            { type: 'SearchRealtiesInput', id: 'LIST' },
          ]
          : [{ type: 'SearchRealtiesInput', id: 'LIST' }],
    }),
    getSearchFiltersData: build.query({
      query: token => ({
        url: links.filters,
        headers: { Authorization: `Bearer ${token}` },
      }),
      transformResponse: responseData => {
        const filtersData = responseData?.data;

        if (filtersData) {
          return filtersData;
        } else {
          return {};
        }
      },
    }),
  }),
});

export const {
  useGetSearchRealtiesQuery,
  useGetSearchInputRealtiesQuery,
  useGetSearchFiltersDataQuery,
} = searchApi;
