import { toast } from 'sonner';

import { FormTemplate } from '../../FormTemplate';
import { InputsTemplate } from '../../../inputs/InputsTemplate';

import {
  initialValues,
  mockExisting,
} from '../../../../../lib/mocks/add-mandate-existing-mock';
import { useEffect, useState } from 'react';
import { showError } from '../../../../../utils/ui';

export const AddMandateForm = () => {
  const [error, setError] = useState('');

  const handleSubmit = () => {};

  useEffect(() => {
    showError(error);
  }, [error]);

  return (
    <div className="flex flex-col gap-4 w-[260px] sm:w-[420px] exclude-click">
      <FormTemplate
        initialValues={initialValues}
        //validationSchema={Yup.object(validationSchemaClient)}
        handleSubmitForm={handleSubmit}
        buttonText={'Save'}
        bgColor={'bg-purpleColor border-purpleColor'}
      >
        {formProps => (
          <InputsTemplate
            formProps={formProps}
            inputsList={mockExisting}
          />
        )}
      </FormTemplate>
    </div>
  );
};
