import { Clock, Pencil, Trash } from 'lucide-react';

export const colors = {
  whiteColor: '#fff',
  blackColor: '#100C09',
  arrowColor: '#111926',
  lightestGrayColor: '#fcfcfc',
  lightGrayColor: '#f5f5f5',
  middleGrayColor: '#c8c8c8',
  grayColor: '#010B13',
  darkGrayColor: '#424242',
  redColor: '#e84855',
  orangeColor: '#fa913c',
  yellowColor: '#fabd23',
  greenColor: '#9bc53d',
  greenMintColor: '#66d48a',
  blueColor: '#52e3e1',
  bluePastelColor: '#bbe5fc',
  darkBlueColor: '#33a8c7',
  purpleColor: '#848cba',
  pinkColor: '#ed0e60',
};

export const fieldTypes = {
  text: 'text',
  search: 'search',
  defaultSearch: 'defaultSearch',
  select: 'select',
  multiselect: 'multiselect',
  selectDepedent: 'selectDepedent',
  multiselectDepedent: 'multiselectDepedent',
  fromTo: 'fromTo',
  calendar: 'calendar',
  keywords: 'keywords',
  toggle: 'toggle',
  radio: 'radio',
  checkbox: 'checkbox',
  checkboxList: 'checkboxList',
  textarea: 'textarea',
  textareaDefaultValue: 'textareaDefaultValue',
  minMax: 'minMax',
};

export const dataTypes = {
  countries: 'countries',
  cities: 'cities',
  districts: 'districts',
  streets: 'streets',
  residences: 'residences',
};

export const inputButtonType = {
  delete: 'delete',
  edit: 'edit',
  add: 'add',
  insert: 'insert',
};

export const selectsLocationNames = {
  country: 'location_country_id',
  countryId: 'country_id',
  agentCountryId: 'agent_country_id',
  agentCityId: 'agent_city',
  city: 'location_city_id',
  cityId: 'city_id',
  cities: 'cities',
  district: 'location_district_id',
  street: 'location_street_id',
  streetNumber: 'location_street_number_id',
  residence_id: 'residence_id',
};

export const contactsInputsNames = {
  contacts: 'contacts',
  contactInput: 'contact_input',
};

export const countryFranceDefault = [
  { id: 73, lowercase: 'france', title: 'France', value: 73 },
];

export const errorMessages = {
  wentWrong: 'Something went wrong',
  sameCategory: 'It is the same category',
  data: 'An error occurred while fetching a data',
  reminders: 'An error occurred while fetching reminders',
  remindersCounters: 'An error occurred while fetching reminders counters',
  videos: 'An error occurred while fetching videos',
  photos: 'An error occurred while fetching photos',
  countriesOrCitites: 'An error occurred while fetching countries or cities',
  contacts: 'An error occurred while fetching contacts',
  notifications: 'An error occurred while fetching notifications',
  agents: 'An error occurred while fetching agents',
  downloadDoc: 'You can not download file before saving',
  renameDoc: 'You can not rename file before saving',
  filters: 'An error occurred while fetching filters for search',
  agentCollaboration:
    'You can not choose a buyer agent as a buyer assistant too',
};

export const successMessages = {
  copyPhone: 'Phone number was successfully coppied',
  copyEmail: 'Email was successfully coppied',
  copyLink: 'Search link was successfully coppied',
  copyAddress: 'The address was successfully coppied',
};

/* export const multiSelectStyles = {
  chips: {
    gap: '4px',
    background: colors.lightGrayColor,
    color: colors.blackColor,
  },
  multiSelectContainer: {
    marginTop: 0,
  },
  searchBox: {
    height: '37px',
    overflow: 'scroll',
    fontSize: '0.875rem',
    border: 'none',
    borderBottom: '1px solid rgb(229 231 235)',
    borderRadius: '0px',
  },
  optionContainer: {
    backgroundColor: '#fff',
  },
}; */

export const tabsTitles = {
  Deals: 'Deals',
  Listings: 'Listings',
  Mandates: 'Mandates',
};

export const tabs = [
  {
    id: 1,
    title: tabsTitles.Deals,
    value: '0',
  },
  {
    id: 2,
    title: tabsTitles.Listings,
    value: 1,
  },
  {
    id: 3,
    title: tabsTitles.Mandates,
    value: 2,
  },
];

export const reminderTypeReschedule = {
  tomorrow: 'tomorrow',
  threeDays: 'three-days',
  nextWeek: 'next-week',
  twoWeek: 'two-week',
  month: 'month',
};

export const reminderTypes = [
  {
    id: 1,
    value: reminderTypeReschedule.tomorrow,
  },
  {
    id: 2,
    value: reminderTypeReschedule.threeDays,
  },
  {
    id: 3,
    value: reminderTypeReschedule.nextWeek,
  },
  {
    id: 4,
    value: reminderTypeReschedule.twoWeek,
  },
  {
    id: 5,
    value: reminderTypeReschedule.month,
  },
];

export const editSelectReminders = [
  {
    id: 1,
    title: 'Edit',
    icon: Pencil,
  },
  {
    id: 2,
    title: 'Reschedule',
    icon: Clock,
  },
  {
    id: 3,
    title: 'Delete',
    icon: Trash,
  },
];

export const myResults = [
  { id: 1, title: 'Buyer visits done', value1: 0, value2: 20 },
  { id: 2, title: 'Contacts found', value1: 0, value2: 20 },
  { id: 3, title: 'Listings visited', value1: 0, value2: 20 },
  { id: 4, title: 'Agreements signed', value1: 0, value2: 20 },
  { id: 5, title: 'Ads published', value1: 0, value2: 20 },
  { id: 6, title: 'Finished reminders', value1: 0, value2: 20 },
  { id: 7, title: 'Listings sent', value1: 5, value2: 203 },
];

export const toggleAndOrList = [
  { id: 1, value: '0', title: 'AND' },
  { id: 2, value: 1, title: 'OR' },
];

export const citiesSelectData = [
  { group: 'CityName', value: 'Paris', title: 'Paris' },
  { group: 'CityName', value: 'Nice', title: 'Nice' },
  { group: 'CityName1', value: 'Strasbourg', title: 'Strasbourg' },
];

export const mockAccordionTitles = {
  Location: 'Location',
  Mandate: 'Mandate',
  Main: 'Main',
  Equipment: 'Equipment',
  Distance: 'Distance',
  Tariff: 'Tariff',
  Contacts: 'Contacts',
  TextDescription: 'Text Description',
  Comment: 'Comment',
  Activity: 'Activity',
  Requests: 'Requests',
  Documents: 'Documents',
  Videos: 'Videos',
  Photos: 'Photos',
};

export const disabledOnAdding = {
  [mockAccordionTitles.Mandate]: true,
  [mockAccordionTitles.Contacts]: true,
  [mockAccordionTitles.Activity]: true,
  [mockAccordionTitles.Requests]: true,
  [mockAccordionTitles.Documents]: true,
  [mockAccordionTitles.Videos]: true,
  [mockAccordionTitles.Photos]: true,
};

export const imagesDownloadButtons = [320, 768, 960];
