import { IconButtonTemplate } from '../../buttons/IconButtonTemplate';

import {
  realtyRoundedButtons,
  realtyRoundedButtonsType,
} from '../../../../data/cardRealty';

export const RoundedButtons = () => {
  const handleMailClick = () => {};

  const handleMatchingClick = () => {};

  const buttonsHandlers = {
    [realtyRoundedButtonsType.mail]: handleMailClick,
    [realtyRoundedButtonsType.matching]: handleMatchingClick,
  };

  return (
    <div className="absolute flex flex-col gap-1 right-1.5 top-1.5 z-[1]">
      {realtyRoundedButtons.map(button => {
        return (
          <IconButtonTemplate
            key={button.id}
            bgColor={button.color}
            handleClick={buttonsHandlers[button.type]}
            icon={button.icon}
            size={18}
            isRounded={button.isRounded}
            classes={button.classes}
          />
        );
      })}
    </div>
  );
};
